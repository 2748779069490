<template>
    <div>
        
        <modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-12 px-12" ref="paymentModal">

            <h3 class="text-xl font-bold mb-16">Make Payment</h3>

            <div>

                <template v-if="getFormError(form)">
                    <div class="alert alert-red-soft -mt-10 mb-10">
                        <span class="alert-icon">!</span>
                        <span>{{ getFormError(form) }}</span>
                    </div>
                </template>
                
                <form-group
                    :left-icon="false"
                    v-model="amountDueInCurrency"
                    :disabled="true"
                >
                    Amount Due
                </form-group>

                <!-- <div class="flex flex-row">

                    <div class="w-3/10">
                        <form-group
                            type="text"
                            :options="paymentOptions"
                            :left-icon="false"
                            name="percentage"
                            :form="form"
                            v-model="percentage"
                            disabled
                        >
                            To Pay
                        </form-group>
                    </div>
                    <div class="w-7/10 pl-4">
                        <form-group
                            :left-icon="false"
                            v-model="amountToPayInCurrency"
                            :disabled="true"
                        />
                    </div>
                    
                </div> -->

                <form-group
                    type="select"
                    :options="paymentMethods"
                    :left-icon="false"
                    name="payment_method"
                    :form="form"
                    v-model="paymentMethod"
                >
                    Payment Method
                </form-group>
                
                <div class="text-center -mt-8">
                    <template v-if="paymentMethod === 'paystack'">
                        <paystack
                            :amount="amountToPay"
                            type="button"
                            class="button bg-blue-500 text-white hover:bg-blue-600"
                            :callback="pay"
                            :disabled="form.loading"
                        >
                            <template v-if="form.loading">Verifying...</template>
                            <template v-else>Make Payment</template>
                        </paystack>
                    </template>
                    <template v-else-if="paymentMethod === 'bank_transfer'">
                        <button
                            type="button"
                            class="button bg-blue-500 text-white hover:bg-blue-600"
                            @click.prevent="showBankTransferModal"
                            :disabled="form.loading"
                        >
                            <template v-if="form.loading">Verifying...</template>
                            <template v-else>Make Payment</template>
                        </button>
                    </template>
                </div>

            </div>


        </modal>

        <modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-12 px-12" ref="bankTransferModal">

            <h3 class="text-xl font-bold mb-16">Payment Information</h3>

            <form @submit.prevent="makeBankTransferRequest">

                <template v-if="getFormError(form)">
                    <div class="alert alert-red-soft -mt-10 mb-10">
                        <span class="alert-icon">!</span>
                        <span>{{ getFormError(form) }}</span>
                    </div>
                </template>
                
                <div class="grid grid-cols-2 gap-8">
                    <div class="col-span-1">
                        <form-group
                            :left-icon="false"
                            right-icon="copy-outline"
                            :right-icon-click="() => copyText(amountToPay)"
                            :disabled="true"
                            v-model="amountToPayInCurrency"
                        >
                            To Pay
                        </form-group>
                    </div>
                    <div class="col-span-1">
                        <form-group
                            :left-icon="false"
                            right-icon="copy-outline"
                            :right-icon-click="() => copyText(accountNumber)"
                            :disabled="true"
                            v-model="accountNumber"
                        >
                            Account Number
                        </form-group>
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-8">

                    <div class="col-span-1">
                        <form-group
                            :left-icon="false"
                            :form="form"
                            right-icon="copy-outline"
                            :right-icon-click="() => copyText(accountNumber)"
                            :disabled="true"
                            v-model="accountName"
                        >
                            Account Name
                        </form-group>
                    </div>
                    <div class="col-span-1">
                        <form-group
                            :left-icon="false"
                            right-icon="copy-outline"
                            :right-icon-click="() => copyText(bankName)"
                            :disabled="true"
                            v-model="bankName"
                        >
                            Bank Name
                        </form-group>
                    </div>

                    
                </div>

                    <form-group
                        :left-icon="false"
                        right-icon="copy-outline"
                        :right-icon-click="() => copyText(reference)"
                        :disabled="true"
                        v-model="reference"
                    >
                        Payment Reference
                    </form-group>
                    
                <div class="grid grid-cols-2 gap-2 -mt-8">
                    <button type="submit" class="button w-full px-5 bg-blue-500 text-white hover:bg-blue-600" :disabled="form.loading">
                        <template v-if="form.loading">Verifying...</template>
                        <template v-else>Mark as Paid</template>
                    </button>

                    <button
                        type="button"
                        class="button w-full px-5 border border-blue-500 text-blue-500 hover:bg-blue-600 hover:text-white"
                        :disabled="form.loading"
                    >
                        <template v-if="form.loading">Verifying...</template>
                        <template v-else>Contact Support</template>
                    </button>
                </div>

            </form>


        </modal>

        <modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-24 px-24 text-center" ref="bankTranserSuccessModal">

            <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

            <div class="text-lg font-bold mb-4">
                Transfer Request Recorded!
            </div>
            <div class="text-sm font-thin mb-20">
                Please wait while our support team approves your payment.
            </div>

            <button
                type="button"
                class="button border border-blue-500 hover:bg-blue-600 text-blue-500 hover:text-white px-16"
                @click.prevent="$refs.bankTranserSuccessModal.close"
            >
                Close
            </button>
        </modal>

        <modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-24 px-24 text-center" ref="paymentSuccessModal">

            <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

            <div class="text-lg font-bold mb-4">
                Payment Successful!
            </div>
            <div class="text-sm font-thin mb-20">
                Your repayment has been marked as paid.
            </div>

            <button
                type="button"
                class="button border border-blue-500 hover:bg-blue-600 text-blue-500 hover:text-white px-16"
                @click.prevent="$refs.paymentSuccessModal.close"
            >
                Close
            </button>
        </modal>

    </div>
</template>

<script>
    export default {
        props: {
            paymentReference: {
                type: String,
                default: ''
            },
            amount: {
                type: [Number, String]
            },
            description: {
                type: String
            },

            error: {
                type: Function,
                default: () => {}
            },
            pay: {
                type: Function,
                default: () => {}
            },
            transfer: {
                type: Function,
                default: () => {}
            },
        },
        data() {
            return {
                checkmark: () => import('@/assets/checkmark-base.svg'),
                form: this.$options.basicForm([
                    {name: 'percentage', value: '100'},
                    {name: 'payment_method', value: 'paystack'},
                ]),
                paymentOptions: [
                    // {title: '25%', value: 25},
                    // {title: '50%', value: 50},
                    // {title: '75%', value: 75},
                    {title: '100%', value: 100}
                ],
                paymentMethods: [
                    { title: 'Card Payment via Paystack', value: 'paystack' },
                    // { title: 'Card Payment via Flutterwave', value: 'rave' },
                    { title: 'Bank Transfer', value: 'bank_transfer' },
                ],
                paymentMethod: 'paystack',
                percentage: '100',
                reference: ""
            }
        },
        mounted() {
            this.reference = this.paymentReference
        },
        computed: {
            accountName() {
                return 'Crednet Technologies';
            },
            accountNumber() {
                return '0815690344';
            },
            bankName() {
                return 'Access Bank';
            },

            amountDue() {
                return this.amount;
            },
            amountDueInCurrency() {
                return `₦ ${this.$options.filters.currency(this.amountDue)}`;
            },
            amountToPay() {
                return this.amountDue * (this.form.data.percentage.value / 100);
            },
            amountToPayInCurrency() {
                return `₦ ${this.$options.filters.currency(this.amountToPay)}`;
            },
        },
        methods: {
            showPaymentModal() {
                this.$refs.paymentModal.open();
            },
            showBankTransferModal() {
                this.$refs.paymentModal.close();
                this.$refs.bankTransferModal.open();
            },
        }
    }
</script>